import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Select, { components } from "react-select";
import "../../Home/SelectBox/SelectBox.scss";

const SelectField = ({name, required, placeholder, labelClass, values, handlechange, fieldClass, label, grpmd, componentprops, isError, selectedValue}) => { 
  const selectOption = [];
  if(placeholder){
    selectOption.push({ value: '', label: placeholder, type:"select"})
  }
 
  values.map((item,index) => {
    selectOption.push({ value: item, label: item, type:"select"})
  })

  //console.log("selectedValue", selectedValue, selectOption)
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none">
      <path d="M6.87077 8.72407L0.923518 0.69473L12.7981 0.680013L6.87077 8.72407Z" fill="#81BB27"/>
      </svg>
      </components.DropdownIndicator>
    );
  };
  
  return(
  <Form.Group  md={grpmd} controlId={`validation${name}`}>
    { label
      ? <Form.Label className={labelClass}>{label}</Form.Label>
      : ''

    }
    {/* <Form.Control
		className={fieldClass}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
    value={componentprops ? componentprops[name] : ''}
		>
		{values.map(val =><option value={val} key="">{val}</option>)}
    </Form.Control> */}
    <div className={"select-group " + (isError ? "has-error" : "")}>
      <Select
        className={fieldClass}
        isSearchable={false}
        // placeholder={placeholder}
        name={name}
        components={{ DropdownIndicator }}
        value={componentprops ? componentprops[name] : ''}
        defaultValue={selectedValue ? selectOption.filter(function(option) {
          return option.value === selectedValue;
        }) : selectOption[0]}
        onChange={handlechange}
        options={selectOption}
        required={required}
        classNamePrefix={"select-opt"}
      />
    </div>
  </Form.Group>
  )
}

export default SelectField;